import { OrgRoleType } from "backend/resources/userRole/types";
import { Form, FormRoot, FormSection } from "shared/ui/form";
import { useActiveOrganizationId } from "state/organization/organization";
import { useOrgs } from "backend/resources/orgRole";
import { OrgMemberCombobox } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { UseFormReturn, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const assignCareTeamSchema = z.object({
  orgMember: z.object({
    organization_id: z.string(),
    org_role: z.string(),
    user_id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    cell_number: z.string(),
    email: z.string(),
  }).optional()
});

export const useAssignCareTeamForm = () => useForm<z.infer<typeof assignCareTeamSchema>>({
  resolver: zodResolver(assignCareTeamSchema),
  defaultValues: {},
  mode: "onSubmit",
});

interface AssignCareNavigatorFormProps {
  form: UseFormReturn<z.infer<typeof assignCareTeamSchema>>;
}

export function AssignCareNavigatorForm({ 
  form
}: AssignCareNavigatorFormProps) {
  const activeOrganizationId = useActiveOrganizationId();
  const { isSuperSuperUser } = useOrgs();

  if (!activeOrganizationId) return null;

  return (
    <Form {...form}>
      <FormRoot id="assign-care-navigator-form">
        <FormSection>
          <div className="flex flex-col items-start gap-4">
            <div className="flex flex-col w-full gap-3">
              <p>Care Navigator</p>
              <OrgMemberCombobox
                form={form}
                orgId={activeOrganizationId}
                role_type={OrgRoleType.CARE_NAVIGATOR}
                isSuperSuperUser={isSuperSuperUser}
              />
            </div>
          </div>
        </FormSection>
      </FormRoot>
    </Form>
  );
}