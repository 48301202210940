import { PAFStatus, Sex } from "components/CarespacePage/PAFSubmission/pafEnums";
import { GuideAlignmentPillColorMap, GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";

export enum DYAD {
  LOW = "Low",
  MODERATE = "Moderate",
  HIGH = "High",
  NA = "N/a",
}

export enum CarespaceTableFields {
  ID = "id",
  CARESPACE = "Carespace",
  DOB = "DOB",
  SEX = "Sex",
  DYAD = "Dyad",
  ORG = "Org",
  STATUS = "Status",
  DOCTOR = "Doctor",
  PCG = "PCG",
  CN = "CN",
  PCP = "PCP"
}

export type CarespaceTableType = {
  [CarespaceTableFields.ID]: string;
  [CarespaceTableFields.CARESPACE]: string;
  [CarespaceTableFields.DOB]: string;
  [CarespaceTableFields.SEX]: Sex;
  [CarespaceTableFields.DYAD]: DYAD;
  [CarespaceTableFields.ORG]: string;
  [CarespaceTableFields.STATUS]: PAFStatus | GuideAlignmentStatus;
  [CarespaceTableFields.DOCTOR]: string;
  [CarespaceTableFields.PCG]: string;
  [CarespaceTableFields.CN]: string;
  [CarespaceTableFields.PCP]: string;
  ["subRows"]?: CarespaceTableType[];
}

export const CarespaceTableColors = {
  [CarespaceTableFields.SEX]: {
    [Sex.UNKNOWN]: "#CFCFCF",
    [Sex.MALE]: "#7DC3F6",
    [Sex.FEMALE]: "#F79393",
  },
  [CarespaceTableFields.DYAD]: {
    [DYAD.NA]: "#CFCFCF",
    [DYAD.LOW]: "#78D08F",
    [DYAD.MODERATE]: "#F6B26B",
    [DYAD.HIGH]: "#F79393"
  },
  [CarespaceTableFields.ORG]: "#8185F0",
  [CarespaceTableFields.STATUS]: {
    ...GuideAlignmentPillColorMap
  }
}
