import { DatePicker } from "@mui/x-date-pickers";
import { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { Sex } from "components/CarespacePage/PAFSubmission/pafEnums";
import { PAFStatusLabelMap } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { CarespaceTableFields, DYAD } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import { getCarespaceNameFromCarespace, getOrganizationNameFromCarespace, getPrimaryCarePhysicianNameFromCarespace, getUserRoleMapFromCarespace } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { Select } from "components/Select";
import { TaskFilter } from "components/TaskNavigatorPage/TaskFilters";
import { FC } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";

interface CarespaceTableFiltersProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
}

const DEFAULT_OPTION = { value: "All", label: "All" };
const TBD_STATUS = "TBD";

export const CarespaceTableFilters: FC<CarespaceTableFiltersProps> = ({ carespaces }) => {
  const { org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace, setOrg, setDob, setSex, setDyad, setDoctor, setPcg, setCn, setPcp, setStatus, setCarespace } = useCarespaceFilterStore();

  const getDefaultOptionMap = () => {
    return { [DEFAULT_OPTION.label]: DEFAULT_OPTION };
  }

  const getCurrentOption = (optionValue: string | undefined) => {
    return { value: optionValue ?? undefined, label: optionValue ?? "All" };
  }

  // build filter options
  const buildFilterOptions = () => {
    const orgOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const carespaceOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const caregiverOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const doctorOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const careNavigatorOptions: Record<string, { value: string; label: string }> = {
      ...getDefaultOptionMap(),
      [TBD_STATUS]: { value: TBD_STATUS, label: TBD_STATUS }
    };
    const pcpOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();

    // iterate through carespaces and add keys to the option maps
    carespaces.forEach((cs) => { // using cs as a variable name for carespace since carespace is alreadybeing used as a state variable from useCarespaceFilterStore
      const carespaceName = getCarespaceNameFromCarespace(cs);
      const pcpName = getPrimaryCarePhysicianNameFromCarespace(cs);
      const orgName = getOrganizationNameFromCarespace(cs);
      const userRolesMap = getUserRoleMapFromCarespace(cs);
      const caregiverName = userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER];
      const doctorName = userRolesMap[NetworkRoleType.DOCTOR];
      const careNavigatorName = userRolesMap[NetworkRoleType.CARE_NAVIGATOR];

      orgOptions[orgName] = { value: orgName, label: orgName };
      pcpOptions[pcpName] = { value: pcpName, label: pcpName };
      carespaceOptions[carespaceName] = { value: carespaceName, label: carespaceName };
      if (caregiverName && !caregiverOptions[caregiverName]) {
        caregiverOptions[caregiverName] = { value: caregiverName, label: caregiverName };
      }
      if (doctorName && !doctorOptions[doctorName]) {
        doctorOptions[doctorName] = { value: doctorName, label: doctorName };
      }
      if (careNavigatorName && !careNavigatorOptions[careNavigatorName]) {
        careNavigatorOptions[careNavigatorName] = { value: careNavigatorName, label: careNavigatorName };
      }
    });

    return {
      orgOptions: Object.values(orgOptions),
      carespaceOptions: Object.values(carespaceOptions),
      caregiverOptions: Object.values(caregiverOptions),
      doctorOptions: Object.values(doctorOptions),
      careNavigatorOptions: Object.values(careNavigatorOptions),
      pcpOptions: Object.values(pcpOptions),
      sexOptions: [DEFAULT_OPTION, ...Object.values(Sex).map((sex) => ({ value: sex, label: sex }))],
      dyadOptions: [DEFAULT_OPTION, ...Object.values(DYAD).map((dyad) => ({ value: dyad, label: dyad }))],
      statusOptions: [DEFAULT_OPTION, ...Object.values(PAFStatusLabelMap).map((status) => ({ value: status, label: status }))]
    };
  };

  const { 
    orgOptions,
    carespaceOptions,
    caregiverOptions,
    doctorOptions,
    careNavigatorOptions,
    pcpOptions,
    sexOptions,
    dyadOptions,
    statusOptions,
   } = buildFilterOptions();

  return (
    <div className={`flex flex-col gap-y-4 w-full`}>
      <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.ORG}>
          <Select
            currentOption={getCurrentOption(org)}
            options={orgOptions}
            onChange={(org) => setOrg(org)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.CARESPACE}>
          <Select
            currentOption={getCurrentOption(carespace)}
            options={carespaceOptions}
            onChange={(carespace) => setCarespace(carespace)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.PCG}>
          <Select
            currentOption={getCurrentOption(pcg)}
            options={caregiverOptions}
            onChange={(pcg) => setPcg(pcg)}
            classNames="w-full"
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.STATUS}>
          <Select
            currentOption={getCurrentOption(status)}
            options={statusOptions}
            onChange={(status) => setStatus(status)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.SEX}>
          <Select
            currentOption={getCurrentOption(sex)}
            options={sexOptions}
            onChange={(sex) => setSex(sex)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.DYAD}>
          <Select
            currentOption={getCurrentOption(dyad)}
            options={dyadOptions}
            onChange={(dyad) => setDyad(dyad)}
            classNames="w-full"
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.DOCTOR}>
          <Select
            currentOption={getCurrentOption(doctor)}
            options={doctorOptions}
            onChange={(doctor) => setDoctor(doctor)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.PCP}>
          <Select
            currentOption={getCurrentOption(pcp)}
            options={pcpOptions}
            onChange={(pcp) => setPcp(pcp)}
            classNames="w-full"
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.CN}>
          <Select 
            currentOption={getCurrentOption(cn)}
            options={careNavigatorOptions}
            onChange={(cn) => setCn(cn)}
            classNames="w-full"
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.DOB}>
          <DatePicker
            value={dob}
            onChange={(dob) => {
              // format the date to mm/dd/yyyy
              const formattedDob = dob ? new Date(dob).toLocaleDateString('en-US') : undefined;
              setDob(formattedDob);
            }}
          />
        </TaskFilter>
      </div>
    </div>
  )
}