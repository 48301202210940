export const QUERY_KEYS = {
  assessmentAnalytics: "assessmentAnalytics",
  assessmentBySlug: "assessmentBySlug",
  respiteServiceActivity: "respiteServiceActivity",
  launchDarklyInfo: "launchDarklyInfo",
  localResources:"localResources",
  billingPageData: "billingPageData",
  videoTasks: "videoTasks",
  chatMessages: "chatMessages",
  chatConversations: "chatConversations",
  scheduleAppointmentTask: "scheduleAppointmentTask",
  notifications: "notifications",
  goal: "goal",
  networkPCP: "networkPCP",
  intercomIdentityHash: "intercomIdentityHash",
  serviceResource: "serviceResource",
  serviceResourcePlanEntry: "serviceResourcePlanEntry",
  serviceEngagements: "serviceEngagements",
  serviceEngagementsToGuideTask: "serviceEngagementsToGuideTask",
  service_engagement_to_document: "service_engagement_to_document",
  serviceEngagementToPlanEntry: "serviceEngagementToPlanEntry",
  serviceEngagementToRequest: "serviceEngagementToRequest",
  serviceResourceToRequest:"serviceResourceToRequest",
  serviceEngagementFromPlanEntry:"serviceEngagementFromPlanEntry",
  archivedGoal: "archivedGoal",
  isFamilyOrganization:"isFamilyOrganization",
  goalUserRecommendationStatus: "goalUserRecommendationStatus",
  goalTemplate: "goalTemplate",
  goalTemplateToRecommendation: "goalTemplateToRecommendation",
  assessmentData: "assessmentData",
  assessmentById:"assessmentById",
  guideTask: "guideTask",
  guideCategory: "guideCategory",
  guideEventToPlanEntry: "guideEventToPlanEntry",
  videoLikes: "videoLikes",
  assessmentTasks: "assessmentTasks",
  adloPreferredName: "adloPreferredName",
  adloDiagnosisType: "adloDiagnosisType",
  userCreatedRecommendations: "userCreatedRecommendations",
  carePlanGoal: "carePlanGoal",
  carePlanGoalDelta: "carePlanGoalDelta",
  carePlan: "carePlan",
  userRole: "userRole",
  orgs: "orgs",
  activeOrg: "activeOrg",
  firstOrg: "firstOrg",
  hasCarebinderControl: "hasCarebinderControl",
  superSuperUsers: "superSuperUsers",
  orgAndIdentities: "orgAndIdentities",
  orgAndCarespaceIdentities: "orgAndCarespaceIdentities",
  userOnboarding: "userOnboarding",
  userAssessmentAnswers: "userAssessmentAnswers",
  mostRecentAssessment: "mostRecentAssessment",
  mostRecentRecommendation: "mostRecentRecommendation",
  carePlanHighLights: "carePlanHighLights",
  carePlanHighlightByInterventionId: "carePlanHighlightByInterventionId",
  recentActivities: "recentActivities",
  assessmentQuestionsCount: "assessmentQuestionsCount",
  videoResults: "videoResults",
  invitation: "invitation",
  assessments: "assessments",
  currentAssessmentQuestionAnswer: "currentAssessmentQuestionAnswer",
  previousAssessmentQuestionAnswer: "previousAssessmentQuestionAnswer",
  assessment: "assessment",
  nextAssessmentTask: "nextAssessmentTask",
  hasPendingReviewAssessments: "hasPendingReviewAssessments",
  videoChannels: "videoChannels",
  network: "network",
  isNetworkAllowed: "isNetworkAllowed",
  networkName: "networkName",
  networksInOrganization: "networksInOrganization",
  networkIds: "networkIds",
  organizationInvitation: "organizationInvitation",
  usersInNetwork: "usersInNetwork",
  videosForChannel: "videosForChannel",
  shareableIntervention: "shareableIntervention",
  mostRecentUnreadAssessmentReviewedNotification:
    "mostRecentUnreadAssessmentReviewedNotification",
  mostRecentAssessmentReviewedNotification:
    "mostRecentAssessmentReviewedNotification",
  videoTasksForMostRecentAssessmentReviewed:
    "videoTasksForMostRecentAssessmentReviewed",
  timeline: "timeline",
  userTimelineStage: "userTimelineStage",
  videoTasksForUserAssessmentId: "videoTasksForUserAssessmentId",
  allPastAssessments: "allPastAssessments",
  userGeneratedVideo: "userGeneratedVideo",
  userGeneratedVideos: "userGeneratedVideos",
  userPrescriptions: "userPrescriptions",
  userAdloNoteDates: "userAdloNoteDates",
  userScheduleDates: "userScheduleDates",
  userDoseTaken: "userDoseTaken",
  userAsNeededDoseTaken: "userAsNeededDoseTaken",
  userDailySchedules: "userDailySchedules",
  userPrescriptionScheduledDoses: "userPrescriptionScheduledDoses",
  userPrescriptionAsNeededScheduledDoses:
    "userPrescriptionAsNeededScheduledDoses",
  userPrescriptionDoseTaken: "userPrescriptionDoseTaken",
  drugInformation: "drugInformation",
  drugScreen: "drugScreen",
  nurseOnCall: "nurseOnCall",
  userVideoStatus: "userVideoStatus",
  assessmentQuestions: "assessmentQuestions",
  pastAssessments: "pastAssessments",
  pastAssessmentsWithScores: "pastAssessmentsWithScores",
  hasTakenAdloAssessment: "hasTakenAdloAssessment",
  userAssessmentSubjectType: "userAssessmentSubjectType",
  latestNonSampleUserAssessment: "latestNonSampleUserAssessment",
  userAssessmentChatGpt: "userAssessmentChatGpt",
  userAssessmentChatGptStatus: "userAssessmentChatGptStatus",
  userAssessmentChatGptInterventionStatus:
    "userAssessmentChatGptInterventionStatus",
  userAssessmentChatGptRecommendationStatus:
    "userAssessmentChatGptRecommendationStatus",
  userAssessmentChatGptInterventionResource:
    "userAssessmentChatGptInterventionResource",
  userAssessmentChatGptRecommendationResource:
    "userAssessmentChatGptRecommendationResource",
  userAssessmentChatGptConversation: "userAssessmentChatGptConversation",
  userAssessmentChatGptMessages: "userAssessmentChatGptMessages",
  conversation_document: "conversation_document",
  latestMessages: "latestMessages",
  latestServiceMessages:"latestServiceMessages",
  latestServiceRequestMessages:"latestServiceRequestMessages",
  userAssessmentChatGptLibraryFolders: "userAssessmentChatGptLibraryFolders",
  userAssessmentChatGptBookmarkToLibraryFolders:
    "userAssessmentChatGptBookmarkToLibraryFolders",
  userAssessmentChatGptBookmarkToTags: "userAssessmentChatGptBookmarkToTags",
  userAssessmentChatGptFeaturedVideos: "userAssessmentChatGptFeaturedVideos",
  userAssessmentChatGptPageView: "userAssessmentChatGptPageView",
  userAssessmentChatGptPdfs: "userAssessmentChatGptPdfs",
  guidePlanEntry: "guidePlanEntry",
  guidePlanEntryIncomingCall: "guidePlanEntryIncomingCall",
  guidePlanEntryWithGUIDETask: "guidePlanEntryWithGUIDETask",
  baseAllPlanEntries: "baseAllPlanEntries",
  guidePlanEntryForCategory: "guidePlanEntryForCategory",
  guidePlanEntryForCall: "guidePlanEntryForCall",
  plan_entry_to_document: "plan_entry_to_document",
  serviceRequests: "serviceRequests",
  guideEvent: "guideEvent",
  userAssessmentChatGptPlanEntries: "userAssessmentChatGptPlanEntries",
  userAssessmentChatGptUpcomingPlanEntries:
    "userAssessmentChatGptUpcomingPlanEntries",
  userAssessmentChatGptPlanEntryToUserBookmarks:
    "userAssessmentChatGptPlanEntryToUserBookmarks",
  userAssessmentChatGptUserUpdate: "userAssessmentChatGptUserUpdate",
  userAssessmentChatGptInProgressUserUpdate:
    "userAssessmentChatGptInProgressUserUpdate",
  userAssessmentChatGptUserUpdateInterventions:
    "userAssessmentChatGptUserUpdateInterventions",
  userAssessmentChatGptUserUpdateRecommendations:
    "userAssessmentChatGptUserUpdateRecommendations",
  userAssessmentChatGptUserUpdateBookmarks:
    "userAssessmentChatGptUserUpdateBookmarks",
  pastNonSampleAssessments: "pastNonSampleAssessments",

  userAssessmentType: "userAssessmentType",
  userAdloInUserAssessment: "userAdloInUserAssessment",
  inProgressAssessment: "inProgressAssessment",
  userAssessmentChatGptRecommendations: "userAssessmentChatGptRecommendations",
  userAssessmentChatGptRecommendation: "userAssessmentChatGptRecommendation",
  carePlanGenerationStatus:
    "carePlanGenerationStatus",
  userAssessmentChatGptIntervention: "userAssessmentChatGptIntervention",
  userAssessmentChatGptInterventions: "userAssessmentChatGptInterventions",
  userAssessment: "userAssessment",
  userBookmarks: "userBookmarks",
  userBookmark: "userBookmark",
  videoCategorization: "videoCategorization",
  userNote: "userNote",
  userDataRequest: "userDataRequest",
  userDeletionRequest: "userDeletionRequest",
  news: "news",
  userAdlo: "userAdlo",
  userAdloPreferredNameAndAge: "userAdloPreferredNameAndAge",
  user: "user",
  interventionResource: "interventionResource",
  chatGptConversation: "chatGptConversation",
  chatGptMessages: "chatGptMessages",
  libraryFolders: "libraryFolders",
  userBookmarkToLibraryFolders: "userBookmarkToLibraryFolders",
  userBookmarkToTags: "userBookmarkToTags",
  featuredVideos: "featuredVideos",
  pageView: "pageView",
  pdfs: "pdfs",
  planEntries: "planEntries",
  planEntryAttendees: "planEntryAttendees",
  upcomingPlanEntries: "upcomingPlanEntries",
  planEntryToUserBookmarks: "planEntryToUserBookmarks",
  userUpdate: "userUpdate",
  inProgressUserUpdate: "inProgressUserUpdate",
  userUpdateInterventions: "userUpdateInterventions",
  UserUpdateRecommendations: "UserUpdateRecommendations",
  recommendationStatus: "recommendationStatus",
  userUpdateBookmarks: "userUpdateBookmarks",
  userUpdateGalleryMedia: "userUpdateGalleryMedia",
  notificationPreferences: "notificationPreferences",
  notification: "notifications",
  carespaceMemberByInvitationId: "carespaceMemberByInvitationId",
  carespaceMembers: "carespaceMembers",
  orgMembers: "orgMembers",
  pafSubmission: "pafSubmission",
  pafCDRAssessments: "pafCDRAssessments",
  pafZBIAssessments: "pafZBIAssessments",
  pafFASTAssessments: "pafFASTAssessments",
  pafPROMISAssessments: "pafPROMISAssessments",
  isSuperSuperUser: "isSuperSuperUser"
};
