import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "App";
import { createCarespace, updateNetworkCareNavigator } from "backend/functions";
import { QUERY_KEYS } from "backend/queryKeys";
import type { InviationUpdate } from "backend/resources/invitation/invitation";
import type { OrganizationRole } from "backend/resources/orgRole";
import { useActiveOrgRole, useOrgs } from "backend/resources/orgRole";
import { PAFSubmission } from "backend/resources/pafSubmission/pafSubmission";
import { PlanEntry } from "backend/resources/planEntry/planEntryTypes";
import type { User } from "backend/resources/user";
import type { UserAdlo } from "backend/resources/userAdlo";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import { useActiveNetworkId } from "state/network/network";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import type { Database } from "types/supabase";

const TABLE = "network";
export type Network = Database["public"]["Tables"]["network"]["Row"];
export type NetworkInsert = Database["public"]["Tables"]["network"]["Insert"];
export type NetworkUpdate = Database["public"]["Tables"]["network"]["Update"];
export type PrimaryCarePhysician = Database["public"]["Tables"]["service_resource"]["Row"]

// helper types
export type NetworksWithAdlosAndCaregivers = Network & {
  user_adlo: UserAdlo[];
  plan_entry: PlanEntry[];
  paf_submission: PAFSubmission[];
  organization: { name: string };
  primary_care_physician: { name: string}
} & { user_role: { user: User, role: NetworkRoleType }[] };

export function useCreateNetworkMutation() {
  // RLS bypass for new carespaces
  const authUser = useUserStore((state) => state.user);
  const activeOrgId = useActiveOrganizationId();

  return useMutation({
    mutationFn: async ({ id, name }: { id?: string; name: string }) => {
      if (!activeOrgId || !authUser) {
        throw new Error(
          "Error in useCreateNetworkMutation, no active organization set."
        );
      }

      const response = await createCarespace({
        name,
        orgId: activeOrgId,
        carespace_creator_id: authUser.id
      });

      return response;
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.networkIds, { userId: authUser?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.networksInOrganization],
      });
    },
  });
}

export const useActiveNetworkName = () => {
  const networkId = useActiveNetworkId();

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.networkName, { networkId }],
    queryFn: () => fetchNetworkName(networkId),
    refetchOnWindowFocus: false,
  });
  return { networkName: data, isLoading, error };
};

export const useNetworkName = (networkId?: string) => {
  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.networkName, { networkId }],
    queryFn: () => fetchNetworkName(networkId),
    refetchOnWindowFocus: false,
  });
  return { networkName: data, isLoading, error };
};

export function useUpdateNetworkNameMutation() {
  const networkId = useActiveNetworkId();
  return useMutation({
    mutationFn: async ({
      providedNetworkId,
      name,
    }: {
      providedNetworkId?: string;
      name: string;
    }) => {
      const selectedNetworkId = providedNetworkId || networkId;
      if (!selectedNetworkId) {
        return { data: null, error: new Error("No networkId provided") };
      }

      const { data, error: updateError } = await supabase
        .from(TABLE)
        .update({ name })
        .eq("id", selectedNetworkId)
        .select();

      return { data, error: updateError };
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.networkName, { networkId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.network, { networkId }],
      });
    },
  });
}


export const useUpdateNetworkGuideStatus = () => {
  return useMutation({
    mutationFn: async ({ guideStatus, networkId }: { guideStatus: string, networkId: string | undefined}) => {
      if (!networkId) return;
      const { data } = await supabase
        .from("network")
        .update({ guide_status: guideStatus, guide_status_updated_at: new Date().toISOString() })
        .eq("id", networkId);
      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.network,
        ]
      });
    }
  });
}

export const useUpdateNetworkPCP = () => {
  return useMutation({
    mutationFn: async ({ pcpId, networkId }: { pcpId: string, networkId: string }) => {
      if (!networkId) return;
      const { data } = await supabase
        .from("network")
        .update({ primary_care_physician_id: pcpId })
        .eq("id", networkId);
      return data
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.networkPCP,
        ]
      });
    }
  });
}

export const useNetworkPCP = () => {
  const networkId = useActiveNetworkId();
  return useQuery({
    queryKey: [QUERY_KEYS.networkPCP, { networkId }],
    queryFn: async () => {
      if (!networkId) return null;
      const { data } = await supabase
        .from("network")
        .select("service_resource(*)")
        .eq("id", networkId)
        .limit(1)
        .maybeSingle()

      return data?.service_resource
    },
    refetchOnWindowFocus: false,
  });
}

export const useAddConversationToActiveNetwork = () => {
  const networkId = useActiveNetworkId();
  return useMutation({
    mutationFn: async (conversationId: string) => {
      if (!networkId) return;
      const { data } = await supabase
        .from("network")
        .update({ conversation_id: conversationId })
        .eq("id", networkId);
      return data
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.network,
        ]
      });
    }
  });
}

export const useNetworksInOrganization = () => {
  const organizationId = useActiveOrganizationId();
  const { isSuperSuperUser } = useOrgs();
  const { data: activeNetwork } = useActiveNetwork();
  const { data: orgRole } = useActiveOrgRole();
  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.networksInOrganization, { organizationId, orgRole, isSuperSuperUser, activeNetwork }],
    queryFn: () =>
      fetchNetworksByOrganizationIdAndActiveOrgRole(organizationId, orgRole, isSuperSuperUser),
  });
  const networks = data?.sort((a, b) => {
    if (a?.name && b?.name) {
      return a.name.localeCompare(b.name);
    }
    return 0;
  }) ?? (activeNetwork ? [activeNetwork!!] : []);

  const carespacesOptionsWithoutAll = networks?.map((network) => ({
    label: network.name || 'Default',
    value: network.id
  })) ?? [];
  const carespacesOptions = [
    { value: "All", label: "All" },
    ...carespacesOptionsWithoutAll
  ];
  return {
    networks,
    carespacesOptions,
    carespacesOptionsWithoutAll,
    isLoading,
    error,
  };
};

export function useNetwork(networkId?: string) {
  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.network, { networkId }],
    queryFn: () => fetchNetworkById(networkId),
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error };
}

export function useActiveNetwork() {
  const activeNetworkId = useActiveNetworkId();
  return useNetwork(activeNetworkId);
}

export function useIsNetworkAllowed(networkId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.isNetworkAllowed, { networkId }],
    queryFn: () => fetchIsNetworkAllowed(networkId),
    refetchOnWindowFocus: false,
  });
}

async function fetchIsNetworkAllowed(networkId?: string | null) {
  if (!networkId) {
    return true;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("id", networkId)

  if (error) {
    throw new Error(error.message);
  }

  return !!data && data.length > 0;
}

async function fetchNetworkById(networkId?: string | null) {
  if (!networkId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select(
      "*, organization(name), primary_care_physician:service_resource(name), user_adlo(*), user_role(user(*), role), paf_submission(id, status)"
    )
    .eq("id", networkId)
    .returns<NetworksWithAdlosAndCaregivers[]>(); // asserts many to many relationships

  if (error) {
    throw new Error(error.message);
  }

  return data && data[0];
}

async function fetchNetworksByOrganizationIdAndActiveOrgRole(
  organization_id: string | undefined,
  orgRole: OrganizationRole | null | undefined,
  isSuperSuperUser: boolean
) {
  if (!organization_id || (!orgRole && !isSuperSuperUser)) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, primary_care_physician:service_resource(name), paf_submission(status), organization(name), user_adlo(*), user_role(user(*), role)")
    .eq("organization_id", organization_id)
    .order("name", { ascending: true })
    .returns<NetworksWithAdlosAndCaregivers[]>(); // asserts many to many relationships
  if (error) {
    throw new Error(error.message);
  }

  // If orgRole's is_superuser field is false, filter out networks
  if (!isSuperSuperUser && !orgRole?.is_superuser) {
    return data?.filter((network) =>
      network.user_role.some((userRole) => userRole.user.id === orgRole?.user_id)
    );
  } else {
    return data
  }

}

async function fetchNetworkName(networkId?: string | null) {
  if (!networkId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("name")
    .eq("id", networkId)
    .limit(1)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data.name;
}

// invitation - user_role - user

async function updateInvitation(invitation: InviationUpdate) {
  if (!invitation.id) return;
  const { data, error } = await supabase
    .from("invitation")
    .update({ ...invitation })
    .eq("id", invitation.id)
    .select();

  if (error) {
    Sentry.captureException(error);
  }

  return data;
}

export function useUpdateInvitationMutation() {
  return useMutation({
    mutationFn: async ({ invitation }: { invitation: InviationUpdate }) => {
      const data = await updateInvitation(invitation);
      return data?.[0];
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.invitation,
          { network_id: variables.invitation.network_id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMemberByInvitationId],
      });
    },
  });
}

export const useUpdateNetworkCareNavigator = () => {
  return useMutation({
    mutationFn: async ({ networkIds, careNavigatorId }: { networkIds: string[], careNavigatorId: string }) => {
      const response = await updateNetworkCareNavigator({
        network_ids: networkIds,
        care_navigator_id: careNavigatorId
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    onSuccess: () => {
      // Network-related invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.network],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.networksInOrganization],
      });
      
      // Plan entry invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.planEntries],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guidePlanEntry],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guidePlanEntryForCategory],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guidePlanEntryForCall],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.baseAllPlanEntries],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guidePlanEntryWithGUIDETask],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.guidePlanEntryIncomingCall],
      });

      // User role invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    }
  });
};
