import { TaskTableRowType } from 'components/Tables/TaskTable/TaskTableTypes';
import { useLocalServiceFilterStore } from 'state/serviceFilters/serviceFilters';
import { create } from 'zustand';

export enum TaskDueDateRange {
  All = "All",
  Today = 'Today',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  ThisQuarter = 'This Quarter',
  ThisYear = 'This Year',
}

export type TaskFilterState = {
  organization: string | undefined;
  carespace: string | undefined;
  category: string | undefined;
  who: string | undefined;
  status: string;
  dueDate: TaskDueDateRange | undefined;
  entryId: string | null | undefined;
  hideDone: boolean;
  taskType: string | undefined;
  guideTask: string | undefined;
  setOrganization: (organization: string | undefined) => void;
  setCarespace: (carespace: string | undefined) => void;
  setCategory: (category: string | undefined) => void;
  setWho: (who: string | undefined) => void;
  setStatus: (status: string | undefined) => void,
  setDueDate: (dueDate: TaskDueDateRange | undefined) => void,
  setEntryId: (entryId: string | null | undefined) => void,
  setHideDone: (hideDone: boolean | undefined) => void;
  setTaskType: (eventType: TaskTableRowType | undefined) => void;
  setGuideTask: (guideTask: string | undefined) => void;
};


const useLocalTaskFilterStore = create<TaskFilterState>((set) => {
  return {
    organization: undefined,
    carespace: undefined,
    category: undefined,
    who: undefined,
    status: "All",
    dueDate: undefined,
    entryId: undefined,
    hideDone: false,
    taskType: undefined,
    guideTask: undefined,
    setOrganization: (organization: string | undefined) => set(() => ({ organization })),
    setCarespace: (carespace: string | undefined) => set(() => ({ carespace })),
    setCategory: (category: string | undefined) => set(() => ({ category })),
    setWho: (who: string | undefined) => set(() => ({ who })),
    setStatus: (status: string | undefined) => set(() => ({ status })),
    setDueDate: (dueDate: TaskDueDateRange | undefined) => set(() => ({ dueDate })),
    setEntryId: (entryId: string | null | undefined) => set(() => ({ entryId })),
    setHideDone: (hideDone: boolean | undefined) => set(() => ({ hideDone })),
    setTaskType: (taskType: TaskTableRowType | undefined) => set((state) => {
      if (taskType === TaskTableRowType.GENERAL || taskType === TaskTableRowType.ServiceRequest) {
        return { taskType, category: undefined, guideTask: undefined };
      }
      return { taskType };
    }),
    setGuideTask: (guideTask: string | undefined) => set(() => ({ guideTask })),
  }
});


export const useTaskFilterStore = () => {
  const localFilter = useLocalTaskFilterStore();

  const setCarespace = (carespace: string | undefined) => {
    syncCarespace(carespace);
  }

  return { ...localFilter, setCarespace }
}

export const syncCarespace = (carespace: string | undefined) => {
  const taskFilterStore = useLocalTaskFilterStore.getState();
  const serviceFilterStore = useLocalServiceFilterStore.getState();

  if (taskFilterStore.carespace !== carespace) {
    taskFilterStore.setCarespace(carespace);
  }
  if (serviceFilterStore.carespace !== carespace) {
    serviceFilterStore.setCarespace(carespace);
  }
};