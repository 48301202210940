import { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { useActiveOrgRole } from "backend/resources/orgRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { AssignCareTeamModal } from "components/HomePage/CareCentralHome/Carespaces/AssignCareTeamModal";
import { CarespaceTableCell } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTableCell";
import { CarespaceTableFields, CarespaceTableType } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import {
  getCarespaceNameFromCarespace,
  getCarespaceStatusFromCarespace,
  getOrganizationNameFromCarespace,
  getPrimaryCarePhysicianNameFromCarespace,
  getUserAdloDobFromCarespace,
  getUserAdloDyadFromCarespace,
  getUserAdloSexFromCarespace,
  getUserRoleMapFromCarespace,
  useFilteredCarespaces
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { FC, useEffect, useState } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";
import { useOrgs } from "backend/resources/orgRole";

interface CarespaceTableProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
  onAddCarespace: () => void;
}

const PENDING_STATUS = "Pending";
const TBD_STATUS = "TBD";

export const CarespaceTable: FC<CarespaceTableProps> = ({
  carespaces, isLoading, onAddCarespace
}) => {
  const navigate = useAppNavigate();
  const { org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace } = useCarespaceFilterStore();
  const [tableData, setTableData] = useState<CarespaceTableType[]>([]);
  const [selectedCarespaces, setSelectedCarespaces] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const { data: activeOrgRole } = useActiveOrgRole();
  const { isSuperSuperUser } = useOrgs();
  const filteredData = useFilteredCarespaces(carespaces);
  
  useEffect(() => {
    const data = filteredData.map(carespace => {
      const userRolesMap = getUserRoleMapFromCarespace(carespace);
      return {
        [CarespaceTableFields.ID]: carespace.id,
        [CarespaceTableFields.CARESPACE]: getCarespaceNameFromCarespace(carespace),
        [CarespaceTableFields.DOB]: getUserAdloDobFromCarespace(carespace),
        [CarespaceTableFields.SEX]: getUserAdloSexFromCarespace(carespace),
        [CarespaceTableFields.DYAD]: getUserAdloDyadFromCarespace(carespace),
        [CarespaceTableFields.ORG]: getOrganizationNameFromCarespace(carespace),
        [CarespaceTableFields.STATUS]: getCarespaceStatusFromCarespace(carespace),
        [CarespaceTableFields.PCP]: getPrimaryCarePhysicianNameFromCarespace(carespace),
        [CarespaceTableFields.DOCTOR]: userRolesMap[NetworkRoleType.DOCTOR],
        [CarespaceTableFields.PCG]: userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER] ?? PENDING_STATUS,
        [CarespaceTableFields.CN]: userRolesMap[NetworkRoleType.CARE_NAVIGATOR] ?? TBD_STATUS,
      } as CarespaceTableType;
    })
    setTableData(data)
  }, [carespaces, org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace])

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCarespaces([]);
    } else {
      setSelectedCarespaces(tableData.map(data => data[CarespaceTableFields.ID]));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectCarespace = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedCarespaces(prev => [...prev, id]);
    } else {
      setSelectedCarespaces(prev => prev.filter(carespaceId => carespaceId !== id));
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {(activeOrgRole?.is_superuser || isSuperSuperUser) && (
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-2">
            <DefaultCheckbox
              checked={selectAll}
              onChange={handleSelectAll}
              label="Select All"
            />
          </div>
          <div style={{ flex: 1 }}></div>
          <div className="flex justify-center items-center" style={{ flex: 2, marginTop: '-110px' }}>
            <ButtonWithIcon
              text="Add Carespace"
              icon={IconOption.PLUS}
              onClick={onAddCarespace}
            />
            <div className="ml-4">
            <ButtonWithIcon
              text="Change Care Team"
              icon={IconOption.EDIT}
              disabled={selectedCarespaces.length === 0}
              onClick={() => setIsAssignModalOpen(true)}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      )}
      <InfiniteScrollingTable
        data={tableData}
        isLoading={isLoading}
        handleClick={({ row }) => 
          navigate({
            path: CareCentralRoute.CARESPACE,
            params: {
              carespaceId: row?.id
            }
          })
        }
        checkboxOptions={(activeOrgRole?.is_superuser || isSuperSuperUser) ? {
          onCheck: (row, isChecked) => handleSelectCarespace(row[CarespaceTableFields.ID], isChecked),
          isChecked: (row) => selectedCarespaces.includes(row[CarespaceTableFields.ID]),
          hideStrikeThrough: true
        } : undefined}
        CellContent={({cell, row}) => <CarespaceTableCell cell={cell} row={row}/>}
        columnFields={Object.values(CarespaceTableFields)}
        headersToCenter={[CarespaceTableFields.SEX, CarespaceTableFields.DYAD, CarespaceTableFields.ORG, CarespaceTableFields.STATUS]}
      />

      <AssignCareTeamModal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        selectedCarespaces={selectedCarespaces}
      />
    </div>
  );
};